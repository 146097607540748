interface AuthButtonGroupProps {
  isAuthenticated: boolean;
  toggleModal: () => void;
  fetchTokensForMonitor: () => void;
  setIsTokenMonitorOpen: (value: boolean) => void;
  handleLogout: () => Promise<void>;
}

const AuthButtonGroup = ({
  isAuthenticated,
  toggleModal,
  fetchTokensForMonitor,
  setIsTokenMonitorOpen,
  handleLogout,
}: AuthButtonGroupProps) => {
  return (
    <div class="flex space-x-0">
      {/* CheckIn Button (opens login modal) */}
      <button
        onClick={toggleModal}
        class={`py-2 px-1.5 rounded-l-xl w-min-[5.5rem] ${
          isAuthenticated ? "bg-orange-400 bg-opacity-60" : "bg-slate-300 hover:bg-slate-200"
        } transition-all duration-300 ease-in-out`}
      >
        <span>{isAuthenticated ? "CheckedIn" : "CheckIn"}</span>
      </button>

      {/* Button to open Token Monitor */}
      <button
        onClick={() => {
          fetchTokensForMonitor(); // Re-fetch tokens when opening the monitor
          setIsTokenMonitorOpen(true);
        }}
        class="bg-slate-400 p-1.5 text-white text-[.6rem] flex flex-col  justify-center items-center leading-none space-y-[3px] hover:opacity-70"
      >
        <span>OAuth</span>
        <span>2.0</span>
        <span class="text-[.45rem] mx-0 px-0">(server-side)</span>
      </button>

      {/* CheckOut Button (logout) */}
      <button
        onClick={handleLogout}
        class={`py-2 px-1.5 rounded-r-xl w-min-[5.5rem] ${
          !isAuthenticated ? "bg-orange-400 bg-opacity-60" : "bg-slate-300 hover:bg-slate-200"
        } transition-all duration-300 ease-in-out`}
      >
        <span>{!isAuthenticated ? "CheckedOut" : "CheckOut"}</span>
      </button>
    </div>
  );
};

export default AuthButtonGroup;
